import isEqual from 'lodash-es/isEqual';

import {
  baseInstance,
  commoditiesInstance,
  companiesInstance as CompaniesAPI,
} from './topicsApi';
import {
  getAPICachingTime,
  returnAPICachingData,
  returnAPICachingHeader,
} from '../../helpers/helpers';
import { removeDuplicates } from '../../helpers/removeDuplicates';
import { copy, removeEmptyProperties } from '../../helpers/commonHelpers';
import { cachingHigh, cachingVeryLow } from '../../data/webPageData';
import { RetryAPICall } from '../../helpers/apiHelpers';

const caches = {
  ratings: [],
  companies: {},
  company: {},
  officers: {},
  fullDetailsList: [],
};

export default class CompaniesSvc {
  static async getCompaniesRatings(params = {}, extra = {}) {
    try {
      const cachedRatings = caches.ratings.find((cache) => isEqual(cache.params, params));
      if (cachedRatings && cachedRatings.expDate > Date.now()) {
        return cachedRatings.response;
      }

      // Splitting the identifier string into chunks of 65 items
      // to avoid too long URLs
      if (typeof params?.identifier === 'string' && params.identifier.length > 2000) {
        const identifiers = params.identifier.split(',');
        const chunkSize = 65;
        return Promise.all(
          Array.from({ length: Math.ceil(identifiers.length / chunkSize) }).map((_, i) => (
            identifiers.slice(i * chunkSize, i * chunkSize + chunkSize).join(',')
          )).map((chunk) => (
            this.getCompaniesRatings({ ...params, identifier: chunk }, extra)
              .catch(() => ({ data: [] }))
          )),
        ).then((responses) => {
          const data = responses.reduce((acc, { data }) => acc.concat(data), []);
          return { data };
        });
      }

      const res = await RetryAPICall(CompaniesAPI, 'ratings', {
        params,
        headers: {
          'Cache-control': returnAPICachingHeader(cachingHigh),
        },
        ...extra,
      });

      caches.ratings.unshift({
        params,
        expDate: getAPICachingTime(cachingHigh),
        response: res,
      });

      return res;
    } catch {
      return {
        data: [],
      };
    }
  }

  static async getCompanyByIdentifier({ identifier }) {
    try {
      const key = identifier;
      if (caches.company[key] && caches.company[key].expDate > Date.now()) {
        return copy(caches.company[key].response);
      }

      const { data } = await RetryAPICall(CompaniesAPI, '', {
        params: {
          identifiers: identifier,
        },
        headers: {
          'Cache-control': returnAPICachingHeader(cachingVeryLow),
        },
      });

      caches.company[key] = {
        expDate: getAPICachingTime(cachingVeryLow),
        response: data || null,
      };
      return copy(data) || null;
    } catch (e) {
      return null;
    }
  }

  static async getCompanyOfficers({
    identifier,
    page = 1,
    per = 100,
  }) {
    try {
      const key = `${identifier}_${page}_${per}`;
      if (caches.officers[key] && caches.officers[key].expDate > Date.now()) {
        return copy(caches.officers[key].response);
      }

      let { data } = await RetryAPICall(CompaniesAPI, 'officers', {
        params: {
          identifier,
          page,
          per,
        },
        headers: {
          'Cache-control': returnAPICachingHeader(cachingVeryLow),
        },
      });
      data = removeDuplicates(data, 'officer_id');

      caches.officers[key] = {
        expDate: getAPICachingTime(cachingVeryLow),
        response: data || null,
      };
      return copy(data) || null;
    } catch (e) {
      return null;
    }
  }

  static async getCommoditiesFullData(params = {}) {
    try {
      const cacheData = returnAPICachingData(caches, 'fullDetailsList', params);
      if (cacheData) return cacheData;

      // Do not add await statement here.
      // It would help to use same cache if 2+ requests are send immediately
      const res = RetryAPICall(commoditiesInstance, 'full_details', {
        params,
        headers: {
          'Cache-control': returnAPICachingHeader(cachingVeryLow),
        },
      }).then((res) => ({
        fullDetails: (res?.data || []).map((details) => ({
          ...details,
          price: details.latest_price || details.price,
          price_usd: details.latest_price || details.price,
          market_cap_usd: details.market_cap_usd || details.market_cap,
          volume_usd_24h: details.volume_usd_24h || details.volume_price_24h,
        })),
      }));

      caches.fullDetailsList.unshift({
        params,
        expDate: getAPICachingTime(cachingVeryLow),
        response: res,
      });

      return res;
    } catch (e) {
      return {
        error: true,
        fullDetails: [{
          ticker: null,
          price: 0,
          price_usd: 0,
          market_cap: 0,
          market_cap_usd: 0,
          volume_price_24h: 0,
          volume_usd_24h: 0,
          volume_percentage_of_total: 0,
          updated_at: null,
        }],
      };
    }
  }

  static async getCommoditiesChartsData(currency, params) {
    const key = `charts_${currency}`;

    const cacheData = returnAPICachingData(caches, key, params);
    if (cacheData) return cacheData;

    // Do not add an await statement here.
    // It would help to use the same cache if 2+ requests are sent immediately
    const response = RetryAPICall(commoditiesInstance, 'chart', {
      params,
    }).then(({ data }) => data);

    if (!caches[key]) {
      caches[key] = [];
    }

    caches[key].unshift({
      params,
      expDate: getAPICachingTime(cachingVeryLow),
      response,
    });

    return response;
  }

  static async getCommoditiesStats(params) {
    try {
      removeEmptyProperties(params);
      const { identifiers } = params;

      const data = await this.getCommoditiesChartsData(identifiers, params);

      return {
        stats: (data || []).map((stat) => ({
          ...stat,
          last_updated: new Date(stat.updated_at * 1000).toISOString(),
          market_cap_usd: stat.market_cap,
          price_usd: stat.price,
          volume_usd_24h: stat.volume_24h,
        })),
      };
    } catch (e) {
      return {
        error: true,
        mainTicker: null,
        stats: [],
      };
    }
  }

  static async getIndicesFullData(params = {}) {
    try {
      const cacheData = returnAPICachingData(caches, 'fullDetailsList', params);
      if (cacheData) return cacheData;

      // Do not add await statement here.
      // It would help to use same cache if 2+ requests are send immediately
      const res = RetryAPICall(baseInstance, 'indices/v1/full_details', {
        params,
        headers: {
          'Cache-control': returnAPICachingHeader(cachingVeryLow),
        },
      }).then((res) => ({
        fullDetails: (res?.data || []).map((details) => ({
          ...details,
          price: details.latest_price || details.price,
          price_usd: details.latest_price || details.price,
          market_cap_usd: details.market_cap_usd || details.market_cap,
          volume_usd_24h: details.volume_usd_24h || details.volume_price_24h,
        })),
      }));

      caches.fullDetailsList.unshift({
        params,
        expDate: getAPICachingTime(cachingVeryLow),
        response: res,
      });

      return res;
    } catch (e) {
      return {
        error: true,
        fullDetails: [{
          ticker: null,
          price: 0,
          price_usd: 0,
          market_cap: 0,
          market_cap_usd: 0,
          volume_price_24h: 0,
          volume_usd_24h: 0,
          volume_percentage_of_total: 0,
          updated_at: null,
        }],
      };
    }
  }

  static async getIndicesChartsData(currency, params) {
    const key = `charts_${currency}`;

    const cacheData = returnAPICachingData(caches, key, params);
    if (cacheData) return cacheData;

    // Do not add an await statement here.
    // It would help to use the same cache if 2+ requests are sent immediately
    const response = RetryAPICall(baseInstance, 'indices/v1/charts', {
      params,
    }).then(({ data }) => data);

    if (!caches[key]) {
      caches[key] = [];
    }

    caches[key].unshift({
      params,
      expDate: getAPICachingTime(cachingVeryLow),
      response,
    });

    return response;
  }

  static async getIndicesStats(params) {
    try {
      removeEmptyProperties(params);
      const { identifiers } = params;

      const data = await this.getIndicesChartsData(identifiers, params);

      return {
        stats: (data || []).map((stat) => ({
          ...stat,
          last_updated: new Date(stat.updated_at * 1000).toISOString(),
          market_cap_usd: stat.market_cap,
          price_usd: stat.price,
          volume_usd_24h: stat.volume_24h,
        })),
      };
    } catch (e) {
      return {
        error: true,
        mainTicker: null,
        stats: [],
      };
    }
  }

  static async getForeignExchangeFullData(params = {}) {
    try {
      const cacheData = returnAPICachingData(caches, 'fullDetailsList', params);
      if (cacheData) return cacheData;

      // Do not add await statement here.
      // It would help to use same cache if 2+ requests are send immediately
      const res = RetryAPICall(baseInstance, 'currencies/v1/full_details', {
        params,
        headers: {
          'Cache-control': returnAPICachingHeader(cachingVeryLow),
        },
      }).then((res) => ({
        fullDetails: (res?.data || []).map((details) => ({
          ...details,
          price: details.latest_price || details.price,
          price_usd: details.latest_price || details.price,
          market_cap_usd: details.market_cap_usd || details.market_cap,
          volume_usd_24h: details.volume_usd_24h || details.volume_price_24h,
        })),
      }));

      caches.fullDetailsList.unshift({
        params,
        expDate: getAPICachingTime(cachingVeryLow),
        response: res,
      });

      return res;
    } catch (e) {
      return {
        error: true,
        fullDetails: [{
          ticker: null,
          price: 0,
          price_usd: 0,
          market_cap: 0,
          market_cap_usd: 0,
          volume_price_24h: 0,
          volume_usd_24h: 0,
          volume_percentage_of_total: 0,
          updated_at: null,
        }],
      };
    }
  }

  static async getForeignExchangeChartsData(currency, params) {
    const key = `charts_${currency}`;

    const cacheData = returnAPICachingData(caches, key, params);
    if (cacheData) return cacheData;

    // Do not add an await statement here.
    // It would help to use the same cache if 2+ requests are sent immediately
    const response = RetryAPICall(baseInstance, 'currencies/v1/chart', {
      params,
    }).then(({ data }) => data);

    if (!caches[key]) {
      caches[key] = [];
    }

    caches[key].unshift({
      params,
      expDate: getAPICachingTime(cachingVeryLow),
      response,
    });

    return response;
  }

  static async getForeignExchangeStats(params) {
    try {
      removeEmptyProperties(params);
      const { identifiers } = params;

      const data = await this.getForeignExchangeChartsData(identifiers, params);

      return {
        stats: (data || []).map((stat) => ({
          ...stat,
          last_updated: new Date(stat.updated_at * 1000).toISOString(),
          market_cap_usd: stat.market_cap,
          price_usd: stat.price,
          volume_usd_24h: stat.volume_24h,
        })),
      };
    } catch (e) {
      return {
        error: true,
        mainTicker: null,
        stats: [],
      };
    }
  }
}
