import { faDatabase } from '@fortawesome/pro-light-svg-icons/faDatabase';
import { faChartPie } from '@fortawesome/pro-light-svg-icons/faChartPie';

import TopicsIcon from '../../assets/images/directory/topic-asset-classes.svg';
import SectorsIcon from '../../assets/images/directory/sectors.svg';
import LocationsIcon from '../../assets/images/directory/locations.svg';
import FilingsIcon from '../../assets/images/directory/filings.svg';
import ESGIcon from '../../assets/images/directory/esg.svg';
import IRIcon from '../../assets/images/directory/ir.svg';
import insiderTransactionsIcon from '../../assets/images/directory/insider-transactions.svg';
import PremiumPublicationsIcon from '../../assets/leftbar/premiumPublicationIcon.svg';

import { INSIDER_TRANSACTIONS } from './topic_classes/tabPaths';
import { UK_PRIVATE_COMPANY_INSIGHTS_PATH } from '../ukPrivateCompanyInsights';

export const SECTORS_PATH_ITEM = 'sectors';
export const DCSC_SECTORS_PATH_ITEM = 'dcsc-sectors';
export const LOCATIONS_PATH_ITEM = 'locations';
export const TOPIC_CLASS_PATH_ITEM = 'topic-class';
export const PREMIUM_PUBLICATIONS_PATH_ITEM = 'premium-publications';

export const DIRECTORIES_PATH = '/news/directory';
export const SECTORS_PATH = `${DIRECTORIES_PATH}/${SECTORS_PATH_ITEM}`;
export const DCSC_SECTORS_PATH = `${DIRECTORIES_PATH}/${DCSC_SECTORS_PATH_ITEM}`;
export const LOCATIONS_PATH = `${DIRECTORIES_PATH}/${LOCATIONS_PATH_ITEM}`;
export const TOPIC_CLASS_PATH = `${DIRECTORIES_PATH}/${TOPIC_CLASS_PATH_ITEM}`;
export const PREMIUM_PUBLICATIONS_PATH = `${DIRECTORIES_PATH}/${PREMIUM_PUBLICATIONS_PATH_ITEM}`;

export const newsTabs = [
  {
    id: 1,
    name: 'topics & asset Classes',
    path: TOPIC_CLASS_PATH,
    icon: {
      src: TopicsIcon,
      width: 45,
      height: 37,
    },
    disabled: false,
  },
  {
    id: 2,
    name: 'sectors',
    path: DCSC_SECTORS_PATH,
    icon: {
      src: SectorsIcon,
      width: 41,
      height: 62,
    },
    disabled: false,
  },
  {
    id: 3,
    name: 'locations',
    path: LOCATIONS_PATH,
    icon: {
      src: LocationsIcon,
      width: 35,
      height: 46,
    },
    disabled: false,
  },
];

export const newsAndContentBySourceTabs = [
  {
    id: 1,
    name: 'Premium Publications',
    path: `${PREMIUM_PUBLICATIONS_PATH}`,
    icon: {
      src: PremiumPublicationsIcon,
      width: 56,
      height: 36,
    },
    disabled: false,
    isBeta: false,
    isNew: false,
    isForPremiumPage: true,
  },
];

export const dataTabs = [
  {
    id: 1,
    name: 'Filings by Location, Source, and Company',
    path: '/directory/filings',
    icon: {
      src: FilingsIcon,
      width: 35,
      height: 46,
    },
    disabled: false,
  },
  {
    id: 2,
    name: 'Investor Relations',
    path: '/directory/investor-relations',
    icon: {
      src: IRIcon,
      width: 61,
      height: 38,
    },
    disabled: false,
  },
  {
    id: 5,
    name: 'Insider Transactions',
    path: `/directory/${INSIDER_TRANSACTIONS}`,
    icon: {
      src: insiderTransactionsIcon,
      width: 41,
      height: 51,
    },
    disabled: false,
    isBeta: false,
    isNew: true,
  },
  {
    id: 3,
    name: 'Environmental. Social. Governance.',
    path: '/directory/esg',
    icon: {
      src: ESGIcon,
      width: 61,
      height: 38,
    },
    disabled: false,
  },
  {
    id: 4,
    name: 'Alternative Data',
    path: '/directory/alternative-data',
    icon: {
      fontIcon: faDatabase,
    },
    disabled: false,
  },
  {
    id: 6,
    name: 'UK Private Company Insights',
    path: UK_PRIVATE_COMPANY_INSIGHTS_PATH,
    icon: {
      fontIcon: faChartPie,
    },
    disabled: false,
    isBeta: true,
    isNew: true,
  },
];

export const orderOfGroups = [
  {
    title: 'Regions',
    name: 'subContinents',
    showMoreButton: false,
  },
  {
    title: 'Countries',
    name: 'countries',
    showMoreButton: true,
  },
  {
    title: 'States, Counties, Provinces',
    name: 'states',
    showMoreButton: true,
  },
  {
    title: 'Cities',
    name: 'cities',
    showMoreButton: true,
  },
  {
    title: 'Areas',
    name: 'areas',
    showMoreButton: true,
  },
  {
    title: 'Places',
    name: 'places',
    showMoreButton: true,
  },
  {
    title: 'Public Companies',
    name: 'stocks',
    showMoreButton: true,
  },
  {
    title: 'Private Companies',
    name: 'private_companies',
    showMoreButton: true,
  },
  {
    title: 'Products',
    name: 'products',
    showMoreButton: true,
  },
  {
    title: 'Indices',
    name: 'indices',
    showMoreButton: true,
  },
];

export const SEO_BREADCRUMBS = [
  {
    name: 'Directory Home',
    path: '',
  },
];

export const TOPIC_CLASS_BREADCRUMBS = [
  ...SEO_BREADCRUMBS,
  {
    name: 'Topics & Asset Classes',
    path: TOPIC_CLASS_PATH_ITEM,
  },
];

export const SECTOR_BREADCRUMBS = [
  ...SEO_BREADCRUMBS,
  {
    name: 'Sectors',
    path: SECTORS_PATH_ITEM,
  },
];

export const DCSC_SECTOR_BREADCRUMBS = [
  ...SEO_BREADCRUMBS,
  {
    name: 'DCSC Sectors',
    path: DCSC_SECTORS_PATH,
  },
];

export const LOCATION_BREADCRUMBS = [
  ...SEO_BREADCRUMBS,
  {
    name: 'Locations',
    path: LOCATIONS_PATH_ITEM,
  },
];

export const topicClassesDescriptions = {
  'Topics & Asset Classes': 'We\'ve made it easy to drill down into the financial and market news universe. Simply choose a topic or asset class to begin your exploratory journey and start reaping the benefits of CityFALCON\'s proprietary machine learning algorithms to guide your way.',
  Stocks: 'A main attraction: stock market news. Here we run the gamut, picking up and processing stories on the stock market today, or in the last hour, or in the last month, or whenever you choose. Find info on large cap companies all the way down to the smallest guys. But take note, stock market news is about just that, the stock market, so these are all public companies.',
  Organisations: 'Guardians, directors, disrupters, and destroyers of the modern world is what you\'ll find here. From political parties within countries to supranational organisations like the UN and EU, from government institutions upholding law and order to revolutionary or reactionary groups tearing it down. Stay up-to-date on the actions, reactions, and policies of the world\'s most influential organisations right here.',
  'Fundamental Analysis': 'Price action can only tell us so much, and fundamental analysis rounds out the full duties of due diligence. Emulating some of the greatest investors of all time will require you to perform FA to find that sweet intrinsic value, and when you do, you can make your move.',
  Commodities: 'Commodities can be a bit boring with all their uniformity, but without them our economies wouldn\'t function at all. So we would like to pay our respect to the commodity market right here. Commodity trading, futures news, and everything commodity related will appear here. Energy and metals build empires, so it is only wise to keep an eye on them.',
  People: 'Want to keep tabs on news surrounding the greatest investors of all time? Or maybe you\'re interested in following up on that corruption scandal involving some world politicians. Or maybe you just want to see what your favorite social trader is tweeting this week. Find it all here in our People directory.',
  'Major Business and Related Activities': 'Scams, scandals, sanctions. Credit analyst upgrades and downgrades. Earnings and divdend announcements. Any and all activities, events, or current state of affairs that affect business are to be found on this page.',
  'Foreign Exchange and Currencies': 'Money makes the world go round, but sometimes we need to convert that money first before it actually goes around the world. Want to track a currency pair? We\'ve got you covered. Interested in general (or specific) forex news? Look no further. Cash is still king, so indulge your appetite for foreign currency information below.',
  'Sovereign and Government Bonds': 'Governments need to pay for all kinds of projects and ongoing maintenance. They don\'t usually carry large cash balances, though - voters would probably want their tax money back if they did - so when a big project comes along, governments need to borrow. Information on sovereign debt from the national level all the way down to the municipal level will appear here. Even those without investments in government bonds might want to track this page, as government debt and its implications often dictate the state of the market.',
  'Mutual Funds': 'Regardless of your preferred mutual fund types, you can find the news and information you need here. Mutual funds with billions in assets under management (AUM) stand alongside their much smaller brethren here. Of course, if you want to filter out some of them, go right ahead. In fact, we encourage it - that\'s the reason we built this whole machine in the first place.',
  'Other Events': 'This is our repository of large scale events, like conferences, summits, and elections. Global sporting events will be here, too, like the Olympics or World Cup. Those two are big money, so anyone interested in making money might want to watch them closely. We mean the news, but watching the games themselves is also a blast.',
  'State-Owned Companies': 'State owned enterprises are not the first thing that comes to mind in the capitalistic world of finance, but that doesn\'t mean they\'re not influential. Plenty of the world\'s biggest companies are wholly government owned, and plenty more are partially goverment owned. Don\'t discount the entities on this page, because they can and do shape the markets and global affairs',
  Indices: 'Global indices (or indexes, if you prefer) track representative baskets of securities or other assets so the market can get an economic pulse for a specific interest. CityFALCON tracks a thoroughly representative basket of indices so users can get an economic pulse for whatever the world is doing. We call it tracking the trackers.',
  'Private Companies and Brands': 'The most valuable private companies were not built overnight, and when they go public, it\'s a big deal. In this section you can get ahead of the curve by tracking news and information on thousands of private companies before the big breakout. While regulatory filings are relatively thin, social media is a godsend for private company investors as you can get the news straight from the source. You\'ll probably need our help sifting through all those tweets and posts, though.',
  'Technical Analysis': 'Topics on trading signals, from the simplest like annual highs and lows to much more complex calculated patterns like the fractal adaptive moving average. Here chartists can track who is tweeting, publishing, and sharing information on technical analysis.',
  Products: 'Products and services are the lifeblood of any company, providing financial sustenance to the top line that ideally travels to the bottom line. The latest technology news will be here, replete with headlines on the latest patent fights and filings alongside announcements of improvements or breakthrough releases. The latest and coolest will make headlines, but fret not if your investments don\'t fall into that category, because we also capture and process product news regarding the boring and mundane. On the darker side, you can track product recalls and their detrimental impact here, too.',
  'Macro Topics': 'These are the real deal. This section showcases topics that affect us all, whether we like it or not. Climate change, tax reform, the fourth industrial revolution, geopolitical struggles. It\'s all here, laid bare for the curious market participant to analyze and digest. To be honest, even casual news consumers should check out this page.',
  'Corporate Bonds': 'The ol\' comrade of equity, corporate bonds form the complement of stocks in the capital markets. Stay current on corporate bond rates, risks, and analysis. These fixed income instruments offer stability until they don\'t - and that latter case, a case of default if you didn\'t get the hint, will definitely generate some stories here.',
  'Ratings & Research Firms': 'Even with CityFALCON\'s assistance on the news aspect, you\'ll probably still want some professional help on the analysis aspect. On this page we serve up the latest from ratings agencies and their activities. We\'ve got the big three (Standard and Poors, Fitch Ratings, and Moodys - if you were wondering). We\'ve got the boutique firms, too, who know the ins-and-outs of whatever niche sector of equity research they\'re tracking. Sell side reports will be here, and, you guessed it, so will the buy side reports. And the neutral reports, to be honest. Keep this page bookmarked, because you\'ll need it after you identify your investment target.',
  'Exchange Traded Funds (ETFs)': 'ETFs provide a simple way to invest in major indices, like S&P 500 ETFs, or in niche and inaccessible markets, like uranium mining firms in a foreign country. Or maybe just green energy ETFs in the domestic market. Either way, ETFs are perfect for small-time investors and big-time investors plus everyone in between.',
  'Financial Regulation and Related Topics': 'We all benefit from a stable, transparent, and trustworthy financial system, which is exactly why we formed financial regulators in the first place. Whether you are interested in particular regulatory bodies like the United States\' SEC or the European Banking Authority or you are interested in a particular piece of financial legislation, you can find a dedicated page for it here.',
  Cryptocurrencies: 'Blockchain tech has created an entirely new asset class, and there are already thousands of assets. Let us serve you up stories on Initial Coin Offerings (ICO) and  Security Token Offerings (STO) and established players too. As new ideas emerge in the space, we\'ll snap them up and add them here. And that means you should check back often, as we\'re doing a lot of snapping these days',
  'Financial Topics': 'This is a place for keeping up with general financial topics. If it deals with finance, it will exist on these pages. This is our catch-all for the most basic finance topics to the most complex. If we don\'t have a page outright dedicated to something, then you\'ve come to the right place, because it won\'t be missing from here.',
  Locations: 'The world is a big place, and even though the Internet and global culture make it seem small, physical location is key in many industries. It also serves as a good way to narrow down your focus, because the world is pumping out so much information all the time and you might get lost in the flood. Use this section to find out what\'s happening by geographic region.',
};

export const LIMIT_IN_EVERY_GROUP = 6;

export const PRICE_DATA_TIMER = 60000;

export const alphaHeaders = [
  'All',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
];

export const LOCATIONS_SENTIMENT_BREADCRUMBS = [
  ...LOCATION_BREADCRUMBS,
  {
    name: 'Sentiment',
    path: '',
  },
];

export const SEO_IR_BREADCRUMBS = [
  ...SEO_BREADCRUMBS,
  {
    name: 'Investor Relations',
    path: 'directory/investor-relations',
  },
];

export const SEO_INSIDER_TRANSACTIONS_BREADCRUMBS = [
  ...SEO_BREADCRUMBS,
  {
    name: 'Insider Transactions',
    path: `/directory/${INSIDER_TRANSACTIONS}`,
  },
];

export const SEO_ESG_BREADCRUMBS = [
  ...SEO_BREADCRUMBS,
  {
    name: 'ESG',
    path: 'directory/esg',
  },
];

export const SEO_ALTERNATIVE_DATA_BREADCRUMBS = [
  ...SEO_BREADCRUMBS,
  {
    name: 'Alternative Data',
    path: 'directory/alternative-data',
  },
];

export const SEO_PREMIUM_PUBLICATIONS_BREADCRUMBS = [
  ...SEO_BREADCRUMBS,
  {
    name: 'Premium Publications',
    path: 'premium-publications',
  },
];

export const TOPICS_SENTIMENT_BREADCRUMBS = [
  ...SEO_BREADCRUMBS,
  {
    name: 'Sentiment',
    path: '',
  },
];

// WEB-6966 add not allowed time filter to prevent making pressure on DB
export const notAllowedTimeFilters = ['months3', 'months6', 'year1', 'lifetime'];
